import React from 'react'
import { Layout } from '../containers/Layout'
import { Container } from '../components/grid/Container'
import { Text } from '../components/Text'
import { HtmlContent } from '../components/HtmlContent/HtmlContent'
import { Block } from '../components/Block'

const Page = () => (
  <Layout fullWidth>
    <Container>
      <Text size="h1" family="merienda" center marginBottom="size7">
        ¿Puedo ayudarte?
      </Text>
      <Block textAlign="center">
        <HtmlContent>
          <p>Gracias por ponerte en contacto conmigo :)</p>
          <p>En breve tendrás noticias mías.</p>
          <p>Un saludo</p>
        </HtmlContent>
      </Block>
    </Container>
  </Layout>
)

export default Page
